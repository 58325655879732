*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: 62.5%;
}

#root {
    height: 100%;
}

input,
textarea,
select {
    font-family: inherit;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

/* Dropzone Uploader Customizations */
.tankmagic .dzu-previewContainer {
    padding: 0 5%;
}
